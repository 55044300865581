<template>
  <v-container
    style="flex-direction: column; background: rgba(0,0,0,0) "
    fluid
    class="molde pa-0 ma-0"
  >
    <v-layout column>
      <v-app-bar
        app
        dense
        v-if="!toolbar_off"
        :fixed="$vuetify.breakpoint.smAndDown"
        class="elevation-0"
        light
        color="#102a2a"
        :style="
          $vuetify.breakpoint.smAndDown
            ? 'margin-bottom: 0px; border-radius: 0px'
            : 'border-radius: 0px;margin-bottom: 0px'
        "
        style="justify-content: center; align-items: center;"
      >
        <!-- <v-btn @click="drawer = !drawer" icon>
          <v-icon :color="$theme.primary">mdi-menu</v-icon>
        </v-btn> -->
        <v-btn
          v-if="$route.path !== '/dashboard'"
          class="mr-2"
          @click="$router.go(-1)"
          icon
        >
          <v-avatar size="34" color="#555" class="elevation-0">
            <v-icon size="26">mdi-arrow-left</v-icon>
          </v-avatar>
        </v-btn>
        <v-btn v-else class="mr-2" @click="drawer = !drawer" icon>
          <v-avatar size="34" color="#555" class="elevation-0 card-balance-style">
            <v-icon size="26" color="white">mdi-menu</v-icon>
          </v-avatar>
        </v-btn>

        <!-- <img width="20" height="20" :src="$theme.logo" /> -->

        <span
          class="fonte-bold pt-1"
          :style="`color: #f2f2f2; font-size: 21pt;`"
          >{{ title }}</span
        >

        <v-spacer></v-spacer>

        <!-- <v-btn small rounded class="ma-0 pa-0 px-1" :color="$theme.primary" outlined>
          <v-avatar size="21">
            <v-img :src="$theme.token_img"></v-img>
          </v-avatar>
          <h3 class="ml-3 fonte"> {{ getBalanceToken.balance }}</h3>
        </v-btn> -->

        <!-- <v-flex v-if="$vuetify.breakpoint.lgAndUp" class="pt-4 px-2" md3>
              <v-text-field solo-inverted dark flat dense v-model="pesquisa" placeholder="Faça uma busca" :color="$theme.primary" append-icon="mdi-magnify"></v-text-field>
          </v-flex> -->

        <!-- <v-btn small v-for="item in options" :color="$theme.primary" class="elevation-0 hidden-sm-and-down animated flipInX delay-1s mr-1" @click="item.action()" :key="item.nome">
              <v-icon class="mr-1" color="#fff"> {{ item.icon }} </v-icon>
              <span class="white--text text-capitalize fonteCash" >{{ item.nome}}</span> 
          </v-btn> -->

        <v-menu v-if="false" bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon :color="$theme.primary">mdi-bell-outline</v-icon>
            </v-btn>
          </template>

          <v-list width="300">
            <v-list-item
              v-for="notification in get_notifications.docs"
              :key="notification._id"
              @click="abre_modal_view_notification(notification)"
            >
              <v-list-item-avatar>
                <v-icon>mdi-bell-outline</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="fonte">{{
                  notification.title
                }}</v-list-item-title>
                <v-list-item-subtitle class="fonte">{{
                  notification.message
                }}</v-list-item-subtitle>
                <v-list-item-subtitle
                  class="orange--text fonte"
                  style="font-size: 9pt;"
                  >{{
                    $moment(notification.created_at).format("llll")
                  }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu dark left bottom>
          <template v-slot:activator="{ on }">
            <v-btn dark icon v-on="on">
              <v-icon>mdi-account-circle-outline</v-icon>
            </v-btn>
          </template>

          <v-list nav dense>
            <v-list-item to="/logged-user">
              <v-list-item-title>{{ getLoggedUser.name }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="logout">
              <v-list-item-title>sair</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu dark v-if="options" left bottom>
          <template v-slot:activator="{ on }">
            <v-btn dark icon v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="item in options"
              :key="item.nome"
              @click="() => item.action()"
            >
              <v-list-item-title>{{ item.nome }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>
      <v-navigation-drawer
        v-model="drawer"
        mini-variant
        expand-on-hover
        color="#102a2a"
        dark
        class="drawer-roller-style"
        style="min-height: 93vh;"
        app
      >
        <v-flex xs12>
          <v-card class="expande"></v-card>
        </v-flex>
        <v-list style="min-width: 100%;" nav class="fonte pa-0 ma-0 px-1">
          <v-list-item
            active-class="menuAtivoHome"
            class="pa-0 ma-0 mb-3 fonte-bold expande-horizontal"
          >
            <v-avatar class="mr-2" size="45" color="yellow">
              <v-icon size="25" color="black">
                mdi-trending-up
              </v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <span class="fonte-bold white--text">RealOperate</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            active-class="menuAtivoHome"
            to="/dashboard"
            class="pa-0 ma-0 fonte-bold expande-horizontal"
          >
            <v-avatar size="45">
              <v-icon
                size="25"
                :color="$route.path === '/dashboard' ? 'white' : 'white'"
              >
                mdi-grid
              </v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <span
                  class="fonte-bold"
                  :class="
                    $route.path === '/dashboard' ? 'white--text' : 'grey--text'
                  "
                  >Dashboard</span
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            active-class="menuAtivoHome"
            to="/notifications"
            class="ml-0 pl-0 fonte-bold"
          >
            <v-avatar size="45">
              <v-icon
                size="25"
                class=""
                :color="$route.path === '/notifications' ? 'white' : 'white'"
              >
                mdi-bell-outline
              </v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <span
                  class="fonte-bold"
                  :class="
                    $route.path === '/notifications'
                      ? 'white--text'
                      : 'grey--text'
                  "
                  >Notificações</span
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- <v-list-group no-action :color="$theme.primary">
            <template v-slot:activator>
              <v-list-item class="ml-0 pl-0 fonte-bold">
                <v-icon size="16" class="mr-6" :color="$theme.primary">
                  mdi-store-outline
                </v-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="fonte-bold">Loja</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            
          </v-list-group> -->

          <!-- <v-list-item
            v-if="show_ativos"
            class="ml-0 pl-0 fonte-bold"
            active-class="menuAtivoHome"
            to="/investments"
          >
            <v-avatar>
              <v-icon
                size="16"
                class="ml-2 mr-6"
                :color="
                  $route.path === '/investments' ? 'white' : $theme.primary
                "
              >
                mdi-chevron-right
              </v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title
                :class="
                  $route.path === '/investments' ? 'white--text' : 'white--text'
                "
                class="fonteCash"
              >
                Planos de Investimento
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->

          <!-- <v-list-item
            v-if="show_produtos"
            class="ml-0 pl-0 fonte-bold"
            active-class="menuAtivoHome"
            to="/products"
          >
            <v-avatar>
              <v-icon
                size="16"
                class="ml-2 mr-6"
                :color="$route.path === '/products' ? 'white' : $theme.primary"
              >
                mdi-chevron-right
              </v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title
                :class="
                  $route.path === '/products' ? 'white--text' : 'white--text'
                "
                class="fonteCash"
              >
                {{ mb ? "Loja" : "Produtos" }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->

          <v-list-item
            v-if="show_planos_de_carreira"
            class="ml-0 pl-0 fonte-bold"
            active-class="menuAtivoHome"
            to="/careers"
          >
            <v-avatar size="45">
              <v-icon
                size="25"
                :color="$route.path === '/careers' ? 'white' : 'white'"
              >
                mdi-crown
              </v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title
                :class="
                  $route.path === '/careers' ? 'white--text' : 'grey--text'
                "
                class="fonteCash"
              >
                Licenças
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            class="ml-0 pl-0 fonte-bold"
            active-class="menuAtivoHome"
            to="/purchases"
          >
            <v-avatar size="45">
              <v-icon
                size="25"
                :color="$route.path === '/purchases' ? 'white' : 'white'"
              >
                mdi-basket-outline
              </v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title
                :class="
                  $route.path === '/purchases' ? 'white--text' : 'grey--text'
                "
                class="fonteCash"
              >
                Minhas Licenças
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            class="ml-0 pl-0 fonte-bold"
            active-class="menuAtivoHome"
            to="/transactions"
          >
            <v-avatar size="45">
              <v-icon
                size="25"
                :color="$route.path === '/transactions' ? 'white' : 'white'"
              >
                mdi-receipt-outline
              </v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title
                :class="
                  $route.path === '/transactions' ? 'white--text' : 'grey--text'
                "
                class="fonteCash"
              >
                Meus Extratos
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            active-class="menuAtivoHome"
            to="/saque"
            class="ml-0 pl-0 fonte-bold"
          >
            <v-avatar size="45">
              <v-icon
                size="25"
                :color="$route.path === '/saque' ? 'white' : 'white'"
              >
                mdi-cash-register
              </v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <span
                  class="fonte-bold"
                  :class="
                    $route.path === '/saque' ? 'white--text' : 'grey--text'
                  "
                  >Saque</span
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            active-class="menuAtivoHome"
            to="/unilevel"
            class="ml-0 pl-0 fonte-bold"
          >
            <v-avatar size="45">
              <v-icon
                size="25"
                :color="$route.path === '/unilevel' ? 'white' : 'white'"
              >
                mdi-account-group-outline
              </v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <span
                  class="fonte-bold"
                  :class="
                    $route.path === '/unilevel' ? 'white--text' : 'grey--text'
                  "
                  >Rede Unilevel</span
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- <v-list-item
            active-class="menuAtivoHome"
            to="/binario"
            class="ml-0 pl-0 fonte-bold"
          >
            <v-avatar size="45">
              <v-icon
                size="25"
                :color="$route.path === '/binario' ? 'white' : 'white'"
              >
                mdi-account-group
              </v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <span
                  class="fonte-bold"
                  :class="
                    $route.path === '/binario' ? 'white--text' : 'grey--text'
                  "
                  >Rede Binária</span
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->

          <!-- <v-list-item
            active-class="menuAtivoHome"
            to="/premiacoes"
            class="ml-0 pl-0 fonte-bold"
          >
            <v-avatar size="45">
              <v-icon
                size="25"
                :color="$route.path === '/premiacoes' ? 'white' : 'white'"
              >
                mdi-school-outline
              </v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <span
                  class="fonte-bold"
                  :class="
                    $route.path === '/premiacoes' ? 'white--text' : 'grey--text'
                  "
                  >Graduação</span
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->

          <v-list-item
            active-class="menuAtivoHome"
            to="/corretora"
            class="ml-0 pl-0 fonte-bold"
          >
            <v-avatar size="45">
              <v-icon
                size="25"
                :color="$route.path === '/corretora' ? 'white' : 'white'"
              >
                mdi-open-in-new
              </v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <span
                  class="fonte-bold"
                  :class="
                    $route.path === '/corretora' ? 'white--text' : 'grey--text'
                  "
                  >Corretora</span
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- rede -->
          <v-list-group
            class="px-0 ma-0"
            v-if="false"
            no-action
            :color="$theme.primary"
          >
            <template v-slot:activator>
              <v-list-item class="ml-0 pl-0 fonte-bold">
                <v-avatar size="30">
                  <v-icon size="25" :color="$theme.primary">
                    mdi-network-outline
                  </v-icon>
                </v-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="fonte-bold grey--text">Rede</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <v-list-item
              class="pl-6"
              active-class="menuAtivoHome"
              to="/unilevel"
            >
              <v-avatar>
                <v-icon
                  :color="
                    $route.path === '/unilevel' ? 'white' : $theme.primary
                  "
                >
                  mdi-chevron-right
                </v-icon>
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title
                  :class="
                    $route.path === '/unilevel' ? 'white--text' : 'white--text'
                  "
                  class="fonte-bold"
                >
                  Minha Rede
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <!-- carreira -->
          <v-list-group v-if="false" no-action :color="$theme.primary">
            <template v-slot:activator>
              <v-list-item class="ml-0 pl-0 fonte-bold">
                <v-avatar class="mr-6" size="16">
                  <v-img :src="$theme.token_img"> </v-img>
                </v-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="fonte-bold">Tokens</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <v-list-item
              class="pl-6"
              active-class="menuAtivoHome"
              to="/premiacoes"
            >
              <v-avatar>
                <v-icon
                  :color="
                    $route.path === '/premiacoes' ? 'white' : $theme.primary
                  "
                >
                  mdi-chevron-right
                </v-icon>
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title
                  :class="
                    $route.path === '/premiacoes'
                      ? 'white--text'
                      : 'white--text'
                  "
                  class="fonte-bold"
                >
                  Graduações
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              class="pl-6"
              active-class="menuAtivoHome"
              to="/ranking"
            >
              <v-avatar>
                <v-icon
                  :color="$route.path === '/ranking' ? 'white' : $theme.primary"
                >
                  mdi-chevron-right
                </v-icon>
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title
                  :class="
                    $route.path === '/ranking' ? 'white--text' : 'white--text'
                  "
                  class="fonte-bold"
                >
                  Ranking
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              class="pl-6"
              active-class="menuAtivoHome"
              to="/transfer-balance/token"
            >
              <v-avatar>
                <v-icon
                  :color="
                    $route.path === '/transfer-balance/token'
                      ? 'white'
                      : $theme.primary
                  "
                >
                  mdi-chevron-right
                </v-icon>
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title
                  :class="
                    $route.path === '/transfer-balance/token'
                      ? 'white--text'
                      : 'white--text'
                  "
                  class="fonte-bold"
                >
                  Transferir Token
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-item
            active-class="menuAtivoHome"
            to="/logged-user"
            class="ml-0 pl-0 mt-3 fonte-bold"
          >
            <v-icon
              size="21"
              class="ml-2 mr-4"
              :color="$route.path === '/logged-user' ? 'white' : 'white'"
            >
              mdi-account-circle
            </v-icon>
            <v-list-item-content>
              <v-list-item-title>
                <span class="fonteCash grey--text fonteMini text-capitalize">
                  {{ getLoggedUser.name }}
                </span>
              </v-list-item-title>
              <v-list-item-subtitle class="grey--text">
                Configurações da conta
              </v-list-item-subtitle>
              <v-list-item-subtitle class="fonte grey--text">
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-main class="molde">
        <!-- <div
          v-if="$vuetify.breakpoint.smAndUp"
          class="expande-horizontal px-6 py-3"
        >
          <v-btn dark @click="$router.go(-1)" icon>
            <v-icon>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <span style="font-size: 18pt;" class="fonte white--text">
            {{ title }}
          </span>
        </div>
        <v-divider dark></v-divider> -->
        <slot></slot>
      </v-main>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["title", "options", "icon", "action", "origem", "toolbar_off"],
  data() {
    return {
      mb: process.env.VUE_APP_MB,
      show_unilevel: process.env.VUE_APP_LOJA_UNILEVEL,
      show_token: process.env.VUE_APP_LOJA_TOKEN,
      show_planos_de_carreira: process.env.VUE_APP_LOJA_CARREIRAS,
      show_produtos: process.env.VUE_APP_LOJA_PRODUTOS,
      show_ativos: process.env.VUE_APP_LOJA_ATIVOS,
      drawer: false
    };
  },
  computed: {
    ...mapGetters(["getLoggedUser", "get_notifications", "getBalanceToken"])
  },
  methods: {
    ...mapActions([
      "list_notifications",
      "logout",
      "abre_modal_view_notification"
    ]),
    goBack() {
      if (this.action !== undefined) {
        this.action();
      } else {
        this.$router.go(-1);
      }
    }
  },
  created() {
    this.drawer = this.$vuetify.breakpoint.smAndUp;
    // this.list_notifications();
  }
};
</script>

<style>
.v-navigation-drawer__border {
  display: none !important;
}
.centraliza-tudo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.molde {
  display: flex;
  width: 100%;
  background: #222; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    45deg,
    #102a2a,
    #102a2a,
    #102a2a
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    45deg,
    #0b1d1d,
    #102a2a,
    #102a2a
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  min-height: 100vh;
}
.card-style {
  background: #ff00cc; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #333399,
    #ff00cc
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #333399,
    #ff00cc
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  background: #fdc830; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #f37335,
    #fdc830
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #f37335,
    #fdc830
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  /* background: #FF8008; 
  background: -webkit-linear-gradient(to right, #FFC837, #FF8008);  
  background: linear-gradient(to right, #FFC837, #FF8008); W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  transition: all 0.3s ease;
  background: #274040 !important; /* fallback for old browsers */
}
.card-style:hover {
  transform: scale(1);
  box-shadow: 0px 4px 8px 0px rgba(15, 52, 3, 0.3),
    0 1px 5px 0 rgba(30, 47, 240, 0.3) !important;
}
.card-balance-style {
  background: #1488cc; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #2b32b2,
    #1488cc
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #2b32b2,
    #1488cc
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  background: #0f0c29; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    45deg,
    #24243e,
    #302b63,
    #0f0c29
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    45deg,
    #24243e,
    #302b63,
    #0f0c29
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  background: #3c3b3f; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    45deg,
    #605c3c,
    #8b8c60,
    #746f48
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    45deg,
    #605c3c,
    #8b8c60,
    #d8c323
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  /* box-shadow: 1px 0px 1px 0px rgba(36, 66, 234, 0.2),
    0 1px 5px 0 rgba(30, 47, 240, 0.2) !important;
  box-shadow: 1px 0px 1px 0px rgba(234, 181, 36, 0.2),
    0 1px 5px 0 rgba(240, 149, 30, 0.2) !important; */
  /* box-shaddow: horizontal vertical blur color */
  /* background: #16A085;
  background: -webkit-linear-gradient(to right, #F4D03F, #16A085); 
  background: linear-gradient(to right, #F4D03F, #16A085); */
  /* transition: scale 0.1s ease;
  transform: background 0.3s ease; */
  background: #274040 !important; /* fallback for old browsers */
}
.card-balance-style:hover {
  transform: scale(1);
  background: #274040 !important;
  box-shadow: 0 0px 8px 0 rgba(38, 77, 44, 0.7);
}
</style>
