import { router } from "@/router";
import LoginService from "../service";

export const actions = {
  createSession({ commit, dispatch }, dados) {
    dispatch("enableLoading");

    LoginService.auth(dados)
      .then(res => {
        dispatch("disableLoading");
        if(res.data.user.block) {
          dispatch("createGlobalMessage", {
            type: "error",
            message: 'Conta Bloqueada, consulte a gerência!',
            timeout: 3000
          });
        } else {
          commit("setLoggedUser", res.data.user);
          commit("setToken", res.data.token);
          sessionStorage.user = JSON.stringify(res.data.user);
          sessionStorage.token = JSON.stringify(res.data.token);
          setTimeout(() => location.reload(), 300);
          router.push("/dashboard");
        }
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.message,
          timeout: 3000
        });
      });
  },
  createUser({ commit, dispatch }, dados) {
    console.log("ok bateu aqui")
    dispatch("enableLoading");
    LoginService.signup(dados)
      .then(() => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "success",
          message: "Cadastrado com sucesso!",
          timeout: 3000
        });
        router.push("/");
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.message,
          timeout: 3000
        });
      });
  },
  sendForgotPassword({ commit, dispatch }, email) {
    dispatch("enableLoading");
    LoginService.forgotPassword({ email })
      .then(() => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "success",
          message: "O email de recuperação foi enviado!",
          timeout: 3000
        });
        commit('setModalForgotPassword', false)
        router.push("/");
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.message,
          timeout: 3000
        });
      });
  },
  sendForgotPasswordFire({ commit, dispatch }, payload) {
    dispatch("enableLoading");
    LoginService.forgotPasswordFire(payload)
      .then(() => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "success",
          message: "Sua senha foi redefinida com sucesso!",
          timeout: 3000
        });
        router.push("/");
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.message,
          timeout: 3000
        });
      });
  },
  activateAccount({ commit, dispatch }, payload) {
    dispatch("enableLoading");
    LoginService.activateAccount(payload)
      .then(() => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "success",
          message: "Sua conta foi ativada com sucesso!",
          timeout: 3000
        });
        router.push("/");
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.message,
          timeout: 3000
        });
      });
  },
  async verifySponsor(state, payload) {
    const sponsor = await LoginService.verifySponsor(payload);
    state.commit("setSponsor", sponsor.data);
    return sponsor.data;
  },
  setModalForgotPassword({ commit }, payload) {
    commit("setModalForgotPassword", payload);
  },
  logout({commit}) {
      sessionStorage.clear()
      commit("setLoggedUser", false);
      commit("setToken", false);
      router.push('/')
  }
};

export default {};
