export const getters = {
  getBalanceUnilevel: state => state.balanceUnilevel,
  getBalancePL: state => state.balancePL,
  getBalanceToken: state => state.balanceToken,
  getBalanceInvestment: state => state.balanceInvestment,
  getUserRanking: state => state.userRanking,
  getMonthlyPayments: state => state.monthlyPayments,
  getRequestPayments: state => state.requestPayments,
  getRequestPayment: state => state.requestPayment,
  getNextMonthlyPayment: state => state.nextMonthlyPayment,
  getTransferBalance: state => state.transferBalance,
  getAccountBanks: state => state.accountBanks,
  getAccountBank: state => state.accountBank,
  getModalViewAccountBank: state => state.modalViewAccountBank,
};

export default {};
