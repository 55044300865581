import AddressService from "../service";
import { router } from "@/router";

export const actions = {
  listAddresses({ commit, dispatch }, data) {
    dispatch("enableLoading");

    AddressService.list()
      .then(res => {
        dispatch("disableLoading");
        commit("setAddresses", res.data);
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.message,
          timeout: 6000
        });
      });
  },
  createAddress({ commit, dispatch }, data) {
    dispatch("enableLoading");

    if(data._id) {
      AddressService.update(data)
        .then(() => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "success",
            message: 'Seu endereço fo atualizado.',
            timeout: 6000
          });
          commit('setModalViewAddress', false)
          dispatch('listAddresses')
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    } else {
      AddressService.createAddress(data)
        .then(() => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "success",
            message: 'Seu novo endereço foi cadastrado.',
            timeout: 6000
          });
          commit('setModalViewAddress', false)
          dispatch('listAddresses')
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    }

  },
  openModalViewAddress({commit, dispatch}, address) {
    if(address._id) {
        commit("setAddress", address);
        commit('setModalViewAddress', true)
      } else {
        commit("setAddress", {});
        commit('setModalViewAddress', true)
    }
  },
  closeModalViewAddress({ commit }) {
    commit('setModalViewAddress', false)
  },
  getCep({ commit, dispatch }, cep) {
    dispatch('enableLoading')
    AddressService.getCep(cep)
      .then(res => {
        dispatch("disableLoading");
        commit("setAddress", {
          postal_code: res.data.cep,
          address: res.data.logradouro,
          neigthborhood: res.data.bairro,
          uf: res.data.uf,
          ddd: res.data.ddd,
          city: res.data.localidade
        });
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.message,
          timeout: 6000
        });
      });
  }
};

export default {};
