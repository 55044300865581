export const unilevel_routes = [
  {
    path: "/unilevel",
    component: () => import("../Main.vue"),
    name: "Minha Rede"
  },
  {
    path: "/binario",
    component: () => import("../MainBinary.vue"),
    name: "Rede binária"
  },
];

export default {};
