import Vue from "vue";
import store from "@/store";
import App from "./App.vue";
import router from "@/router";
import vuetify from "@/plugins/vuetify";
import mask from "vue-the-mask";
import moment from 'moment'
import 'moment/locale/pt-br'
import "@/apps/shared";
import Helper from "./helper";
import { db, storage } from './apps/shared/firebase'
import run from "./apps/shared/Onoff";
import VueCropper from "vue-cropper";

Vue.use(VueCropper);
Vue.use(mask);
Vue.prototype.$moment = moment
Vue.config.productionTip = false;
Vue.prototype.$toLocaleString = (value) => {
  return value.toLocaleString('pt-br')
}

const theme = {
  // primary: process.env.VUE_APP_COLOR,
  primary: "#555",
  // logo: 'https://firebasestorage.googleapi1s.com/v0/b/cosmos-home-site.appspot.com/o/logotipo%20unbox.png?alt=media&token=0e378442-60ff-4eb7-84b2-731f13c2a7ba',
  logo: process.env.VUE_APP_LOGO,
  background: process.env.VUE_APP_BACKGROUND,
  token_img: process.env.VUE_APP_TOKEN_IMG
  //mb
  // logo: 'https://firebasestorage.googleapis.com/v0/b/mb-invest-6509d.appspot.com/o/logo.png?alt=media&token=8b5d5a40-3567-433e-8518-66ff519a47b4',
  // background: 'https://steamcdn-a.akamaihd.net/steamcommunity/public/images/items/96100/f400290347611bd57a63728d10bc87131ad0d8a9.jpg',
  // token_img: "https://firebasestorage.googleapis.com/v0/b/mb-invest-6509d.appspot.com/o/mbtoken.jpg?alt=media&token=b789bc46-25f3-4089-984f-303e77537118"
  //sp7
  // logo: 'https://firebasestorage.googleapis.com/v0/b/sp7site.appspot.com/o/sp7mini.png?alt=media&token=e4260669-0357-44c6-92ba-3ebc7886b253',
  // token_img: "https://firebasestorage.googleapis.com/v0/b/mb-invest-6509d.appspot.com/o/mbtoken.jpg?alt=media&token=b789bc46-25f3-4089-984f-303e77537118",
  // background: 'https://5be8c0f6d904e0044d904cc1.static-01.com/l/images/70053fede3755c3807479a12977932e868f5d4b7.jpg',
  //michl
  // logo: 'https://firebasestorage.googleapis.com/v0/b/investment-mi.appspot.com/o/logo-white.png?alt=media&token=42e5d050-27b7-4a15-ad7f-094c70041534',
  // token_img: "https://firebasestorage.googleapis.com/v0/b/mb-invest-6509d.appspot.com/o/mbtoken.jpg?alt=media&token=b789bc46-25f3-4089-984f-303e77537118",
  // background: 'https://steamcdn-a.akamaihd.net/steamcommunity/public/images/items/96100/f400290347611bd57a63728d10bc87131ad0d8a9.jpg',
};

Vue.prototype.$theme = theme;
Vue.prototype.$db = db;
Vue.prototype.$storage = storage;
Vue.prototype.$helper = new Helper();
Vue.prototype.$run = run;


new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount("#app");
