export const purchase_routes = [
  {
    path: "/purchases",
    component: () => import("../Main.vue"),
    name: "Compras"
  },
  {
    path: "/purchases-canceleds",
    component: () => import("../MainCanceleds.vue"),
    name: "Compras Canceladas"
  },
  {
    path: "/purchase",
    component: () => import("../Purchase.vue"),
    name: "Nova Compra"
  }
];

export default {};
