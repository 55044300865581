import PurchaseService from "../service";
import router from "@/router";

export const actions = {
  listPurchases({ commit, dispatch }, quiet) {
    if (!quiet) {
      dispatch("enableLoading");
    }
    PurchaseService.list()
      .then(res => {
        dispatch("disableLoading");
        commit("setPurchases", res.data);
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "warning",
          message: e.responsa.data.message,
          timeout: 6000
        });
      });
  },
  openPurchase({ commit, dispatch }, payload) {
    commit("setPurchase", payload);
    dispatch("listPaymentMethods")
    dispatch("listAddresses")
    router.push("/purchase");
  },
  viewPurchase({ commit, dispatch }, payload) {
    PurchaseService.view({ _id: payload._id })
      .then(res => {
        dispatch("disableLoading");
        commit("setPurchase", res.data);
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "warning",
          message: e.responsa.data.message,
          timeout: 6000
        });
      });
    commit("setModalViewPurchase", true);
  },
  cancelPurchase({ commit }) {
    commit("setModalViewPurchase", false);
    commit("setModalViewPurchaseActive", false);
    commit("setPurchase", {});
  },
  createPurchase({ commit, dispatch, getters }) {
    dispatch("enableLoading");
    PurchaseService.createPurchase({
      item: getters.getPurchase,
      ...getters.getPurchase,
      paymentMethod: getters.getPaymentMethod,
      paymentMethod_id: getters.getPaymentMethod._id ? getters.getPaymentMethod._id : 'token',
      links: getters.getLinks
    })
      .then(() => {
        commit("setModalViewPurchase", false);
        dispatch("disableLoading");
        dispatch("cancelPurchase")
        dispatch("listPurchases");
        dispatch("createGlobalMessage", {
          type: "success",
          message: "Sua compra foi enviada para processamento com sucesso!",
          timeout: 6000
        });
        router.push("/purchases");
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("cancelPurchase")
        dispatch("createGlobalMessage", {
          type: "warning",
          message: e.response.data.message,
          timeout: 6000
        });
      });
  },
  // viewPurchase({ commit, dispatch }, payload) {
  //   dispatch("enableLoading");

  //   PurchaseService.view(payload)
  //     .then(res => {
  //       dispatch("disableLoading");
  //       commit("setPurchase", res.data);
  //       commit("setModalViewPurchaseActive", true);
  //     })
  //     .catch(e => {
  //       dispatch("disableLoading");
  //       dispatch("createGlobalMessage", {
  //         type: "warning",
  //         message: e.responsa.data.message,
  //         timeout: 6000
  //       });
  //     });
  // }
};

export default {};
